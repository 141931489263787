import * as React from "react"
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../Container"
import { Link } from "gatsby";
import Navigation from "../Navigation";
import { useState } from "react";
import { useEffect } from "react";


const Header = () =>{

    const [offset, setOffset] = useState(0);
    const [width, setWidth] = useState()
    const [menuState, setMenuState] = useState(false)

    const data = useStaticQuery(graphql`
    query LogoQuery{
        wp {
            siteLogo {
                altText
                mediaItemUrl
            }

        }
        wpMenu(locations: {eq: PRIMARY}) {
            menuItems {
              nodes {
                cssClasses
                uri
                label
                nodeType
                childItems {
                  nodes {
                    uri
                    label
                    description
                    connectedNode {
                      node {
                        ... on WpOplossing {
                          id
                          oplossingen {
                            icon {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                    }
                  }
                }
                parentId
              }
            }
          }
    }
    `)

    useEffect(() => {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }, []);

    useEffect(()=>{
      setWidth(window.innerWidth)
    })

  const menuHandler = () => {
    setMenuState((prevState)=>(prevState ? false : true))
  }

    return(
        <header className={`headerClass sticky top-0 lef-0 z-50 py-5 ${offset > 50 ? 'shadow' : ''} `}>
          <div className={'mx-auto flex flex-row justify-between items-center max-w-screen-2xl px-4'}>
              <div onClick={menuState ? menuHandler : null} className='z-50'><Link to="/"><img src={data.wp.siteLogo.mediaItemUrl} style={{width: width > 500 ? '200px' : '150px'}}></img></Link></div>
              <Navigation data={data.wpMenu} width={width} handler={menuHandler} state={menuState}></Navigation>
              <div id={`nav-icon1`} className={`${menuState ? 'open' : ''} lg:hidden z-50`} onClick={menuHandler}>
                <span></span>
                <span></span>
                <span></span>
              </div>
          </div>
        </header>
    )
}

export default Header;